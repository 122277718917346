import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDataById, getDataByQuery } from "../dbFuntions";
import CardGrid from "../components/CardGrid";

const Store = () => {
  const { storeId } = useParams();
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch store details
    getDataById("Store/", storeId)
      .then((storeData) => {
        setStore(storeData);
      })
      .catch((error) => {
        console.error("Error fetching store data:", error);
      });

    // Fetch products uploaded by the store
    getDataByQuery("Products/", { orderBy: "storeId", equalTo: storeId })
      .then((productsData) => {
        setProducts(productsData);
      })
      .catch((error) => {
        console.error("Error fetching products data:", error);
      });
  }, [storeId]);

  return (
    <div className="container mx-auto mt-8">
      {store && (
        <div className="text-center mb-8">
          <img
            src={store.image}
            alt={store.storeName}
            className="mx-auto w-32 h-32 rounded-full mb-4"
          />
          <h2 className="text-2xl font-semibold mb-2">{store.storeName}</h2>
          <p className="text-gray-600 mb-2">
            Store Description: {store.description}
          </p>
          <div className="flex items-center justify-center mb-4">
            <div className="flex items-center">
              {[...Array(5)].map((_, index) => (
                <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 ${
                    index < store.rating ? "text-yellow-500" : "text-gray-300"
                  }`}
                  fill={index < store.rating ? "yellow" : "white"}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4l1.55 3.9L18 9.24l-3.3 2.54.92 4.63L12 15.4l-4.62 2.01.92-4.63L6 9.24l4.45-1.34L12 4z"
                  />
                </svg>
              ))}
              {/*<p className="ml-2">Rating: {store.rating}</p>*/}
            </div>
          </div>
        </div>
      )}

      <h2 className="text-2xl font-semibold mb-4">Products</h2>
      {products && products.length > 0 ? (
        <CardGrid data={products} gridSize={4} />
      ) : (
        <p>No products uploaded by this store yet</p>
      )}
    </div>
  );
};

export default Store;
