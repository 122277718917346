//import { useEffect, useState } from 'react'
import { useContext } from "react";
import artGallery from "../images/art-gallery.png";
import { Context as GeneralContext } from "../context/GeneralContext";
import { Link } from "react-router-dom";

function Slider() {
  const { state } = useContext(GeneralContext);

  /* const [state, setState] = useState(false)

    useEffect(() => {
        document.onclick = (e) => {
            const target = e.target;
            if (!target.closest(".menu-btn")) setState(false);
        };
    }, [])*/

  return (
    <div className="relative">
      <div
        className="absolute inset-3 blur-xl h-[450px]"
        style={{
          background:
            "linear-gradient(143.6deg, rgba(252, 244, 143, 0) 20.79%, rgba(249, 226, 121, 0.26) 40.92%, rgba(238, 220, 204, 0) 70.35%)",
        }}
      ></div>
      <div className="relative">
        <section>
          <div className="max-w-screen-xl mx-auto px-4 py-28 gap-12 text-gray-600 overflow-hidden md:px-8 md:flex">
            <div className="flex-none space-y-5 max-w-xl">
              <h1 className="text-4xl text-gray-800 font-extrabold sm:text-5xl">
                Welcome to
                <span className="text-yellow-600"> ArtHub</span>
              </h1>
              <p className="mb-4">
                All you need to know about art in one place. Make your digital
                art gallery with chances to learn about art from the experts.
              </p>
              {state.user === null ? (
                <div className="flex items-center gap-x-3 sm:text-sm">
                  <Link
                    to={"/signin"}
                    className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-gray-800 duration-150 hover:bg-yellow-600 active:bg-gray-900 rounded-full md:inline-flex"
                  >
                    Sign In
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="flex-1 hidden md:block">
              <img
                src={artGallery}
                alt="artgallery"
                className="max-w-x2 mx-auto mt-[-40px] md:mt+20 max-h-[400px]"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Slider;
