import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

function Layout(props) {
  return (
    <div className="px-4 py-4 max-w-screen-xl mx-auto md:px-8">
      <NavigationBar />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
}

export default Layout;
