import { Link } from "react-router-dom";

function Card({ screen, items, ke }) {
  const type = items?.price ? "product" : "class";
  const id = items?.productId ? items?.productId : items?.courseId;
  const image = items?.thumbnail ? items?.thumbnail : items?.images;
  const title = items?.courseName ? items?.courseName : items?.productName;
  const maxDescriptionLength = 100; // Set the maximum length of the description

  // Function to truncate the description to the maximum length
  const truncateDescription = (description) => {
    if (description && description.length > maxDescriptionLength) {
      return description.slice(0, maxDescriptionLength) + "...";
    }
    return description;
  };

  // Determine the path based on whether it's a course or a product
  const path = type === "class" ? `/class/${id}` : `/ItemDetail/${id}`;
  let navigateToScreen = null;
  if (screen === "DashBoard/Product") {
    navigateToScreen = `/EditProductForm/${id}`;
  } else if (screen === "DashBoard/Class") {
    navigateToScreen = `/EditClassForm/${id}`;
  } else {
    navigateToScreen = path;
  }

  return (
    <article
      className="max-w-md mx-auto mt-4 shadow-lg border rounded-md duration-300 hover:shadow-sm bg-white w-full md:w-11/12"
      key={ke}
    >
      <Link to={navigateToScreen} className="w-full flex-col flex">
        {image && type === "class" && (
          <img
            src={image}
            loading="lazy"
            alt={items.title}
            className="w-72 h-48 rounded-t-md object-contain self-center"
          />
        )}

        {image && type === "product" && (
          <img
            src={image[0]}
            loading="lazy"
            alt={items.title}
            className="w-72 h-48 rounded-t-md object-contain self-center"
          />
        )}

        <div className="pt-3 ml-4 mr-2 mb-3">
          <h3 className="text-xl text-gray-900">{title}</h3>
          <div className="flex">
            Rating:
            {[...Array(5)].map((_, index) => (
              <svg
                key={index}
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 ${
                  index < items.rating ? "text-yellow-500" : "text-gray-300"
                }`}
                fill={index < items.rating ? "yellow" : "white"}
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4l1.55 3.9L18 9.24l-3.3 2.54.92 4.63L12 15.4l-4.62 2.01.92-4.63L6 9.24l4.45-1.34L12 4z"
                />
              </svg>
            ))}
          </div>
          <p className="text-gray-400 text-sm mt-1">
            {truncateDescription(items.description)}{" "}
            {/* Truncated description */}
          </p>
          {items?.price && (
            <p className="text-gray-900 text-md mt-1">£{items.price}</p>
          )}
        </div>
      </Link>
    </article>
  );
}

export default Card;
