import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getClassById, getDataById } from "../dbFuntions";
import ReviewsComponent from "../components/Reviews";

const ClassDescription = () => {
  const { classId } = useParams();
  const [classDetails, setClassDetails] = useState(null);
  const [courseclass, setCourseclass] = useState(null);
  //const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    // Fetch class details by ID
    getClassById(classId)
      .then((details) => {
        setClassDetails(details);
        getDataById("Class/", details.classId)
          .then((classInfo) => {
            setCourseclass(classInfo);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });

    // Fetch feedback for the class
    /*getFeedbackByClassId(classId)
      .then((feedbackData) => {
        setFeedback(feedbackData);
      })
      .catch((err) => {
        console.log(err);
      });*/
  }, [classId]);

  if (!classDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto py-8">
      <div className="flex justify-center">
        {/* Video Player */}
        <div className="w-full lg:w-3/4">
          {/* You can integrate your video player component here */}
          <video controls className="w-full">
            <source src={classDetails.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="mt-8">
        {/* Class Name */}
        <h2 className="text-3xl font-bold  mb-4">{classDetails.courseName}</h2>
        {/* Display class profile picture and name */}
        {courseclass && (
          <Link
            to={`/classpage/${classDetails.classId}`}
            className="flex items-center mb-2"
          >
            {/*<p className="text-lg font-bold my-2">Class: </p>*/}
            <img
              src={courseclass.image}
              alt="Store Profile"
              className="w-10 h-10 rounded-full mr-2"
            />
            <span className="text-lg font-semibold">
              {courseclass.className}
            </span>
          </Link>
        )}
        {/* Course Description */}
        <p className="text-lg  mb-4 text-justify">{classDetails.description}</p>
        {/* Uploader Details */}
        <div className="mb-4">
          {/*<p>Uploaded By: {classDetails.uploader}</p>
           You can include more uploader details if needed */}
        </div>
        {/* Feedback and Reviews Section */}
        <div className="mt-8">
          <ReviewsComponent screen={"ClassDescription"} id={classId} />
        </div>
      </div>
    </div>
  );
};

export default ClassDescription;
