import React, { useContext, useEffect, useState } from "react";
import { readFromDatabase } from "../dbFuntions";
import { Context as GeneralContext } from "../context/GeneralContext";

const Item = ({ pic, name, quantity, price, orderStatus }) => {
  return (
    <div className="flex items-center justify-between  md:p-4 py-4 border-b">
      <img
        src={pic}
        alt={name}
        className="hidden md:block md:w-20 md:h-20 md:object-contain md:border md:border-1 md:rounded-lg"
      />
      <span className="flex-1 md:ml-4 mr-3 font-semibold">{name}</span>
      <span className="w-20 text-center">{quantity}</span>
      <span className="w-20 text-center">${price}</span>
      <span className="w-32 text-center">{orderStatus}</span>
    </div>
  );
};

const MyOrders = () => {
  const { state } = useContext(GeneralContext);
  const [underReviewProducts, setUnderReviewProducts] = useState([]);
  const [completedProducts, setCompletedProducts] = useState([]);
  useEffect(() => {
    readFromDatabase("UnderProcessOrders/")
      .then((details) => {
        const filteredOrders = Object.entries(details).reduce(
          (acc, [key, value]) => {
            if (value.userId === state?.user?.userId) {
              acc.push({
                ...value, // Spread the existing order data
                orderId: key, // Add the parent key as orderId
              });
            }
            return acc;
          },
          []
        );
        setUnderReviewProducts(filteredOrders);
      })
      .catch((err) => {
        console.log(err);
      });

    readFromDatabase("CompletedOrders/")
      .then((details) => {
        const filteredOrders = Object.entries(details).reduce(
          (acc, [key, value]) => {
            if (value.userId === state?.user?.userId) {
              acc.push({
                ...value, // Spread the existing order data
                orderId: key, // Add the parent key as orderId
              });
            }
            return acc;
          },
          []
        );
        setCompletedProducts(filteredOrders);
      })

      .catch((err) => {
        console.log(err);
      });
  }, [state?.user?.userId]);

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-lg mt-8">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-semibold mb-4">My Orders</h2>
          <h3 className="text-xl font-semibold mb-2">Current Orders</h3>
          <div className="flex items-center justify-between md:p-4 py-4 border-b">
            <span className="hidden md:flex md:flex-1 md:ml-4 md:font-semibold">
              Image
            </span>
            <span className="flex-1 md:ml-4 mr-3 font-semibold">Name</span>
            <span className="w-20 text-center font-semibold">Quantity</span>
            <span className="w-20 text-center font-semibold">Price</span>
            <span className="w-32 text-center font-semibold">Order Status</span>
          </div>
          {underReviewProducts.map((product) => (
            <Item
              key={product.orderId}
              pic={product.productImage} // Replace 'defaultImageURL' with actual URL or leave as is
              name={product.name}
              quantity={product.quantity}
              price={product.price}
              orderStatus={"Pending"} // Assuming status is not part of product; adjust as necessary
            />
          ))}
          <h3 className="text-xl font-semibold mb-2">Past Orders</h3>
          {completedProducts.map((product) => (
            <Item
              key={product.orderId}
              pic={product.pic} // Replace 'defaultImageURL' with actual URL or leave as is
              name={product.name}
              quantity={product.quantity}
              price={product.price}
              orderStatus={"Dispatched"} // Assuming status is not part of product; adjust as necessary
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
