import { useState } from "react";
import { auth } from "../firebase"
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [resetEmailSent, setResetEmailSent] = useState(false);
    const [resetError, setResetError] = useState('');

    const handleResetPassword = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setResetEmailSent(true);
                setResetError('');
            })
            .catch((error) => {
                setResetEmailSent(false);
                setResetError(error.message);
            });
    };

    return (
        <main className="w-full h-screen flex flex-col items-center justify-center px-4">
            <div className="max-w-sm w-full text-gray-600 space-y-5">
                <div className="text-center pb-8">
                    <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">Forgot Password</h3>    
                </div>
                <form onSubmit={handleResetPassword} className="space-y-5">
                    {resetEmailSent ? (
                        <p className="text-green-500 text-center mb-4">Password reset email sent. Please check your inbox.</p>
                    ) : (
                        <>
                            <p className="text-red-500 text-center mb-4">{resetError}</p>
                            <div>
                                <label className="font-medium">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
                                    placeholder="Enter your email address"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 text-white font-medium bg-yellow-600 hover:bg-yellow-500 active:bg-yellow-600 rounded-lg duration-150"
                            >
                                Send Reset Email
                            </button>
                        </>
                    )}
                </form>
                <div className="text-center">
                    <p>Remember your password? <Link to="/signin" className="font-medium text-yellow-600 hover:text-yellow-500">Sign in</Link></p>
                </div>
            </div>
        </main>
    );
}

export default ForgotPassword;
