import React, { useState, useContext, useEffect } from "react";
import { Context as GeneralContext } from "../context/GeneralContext";
import { pushToDatabase } from "../dbFuntions";
import { useNavigate } from "react-router-dom";
import visaLogo from "../images/Visa.png";
import mastercardLogo from "../images/MasterCard.png";

const CustomDropdown = ({
  paymentMethod,
  cards,
  selectedCard,
  handleCardSelectionChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (cardNumber) => {
    handleCardSelectionChange(cardNumber);
    setIsOpen(false);
  };

  const renderCardOption = (card) => {
    let cardLogo = null;

    if (card.cardType === "Visa") {
      cardLogo = visaLogo;
    } else if (card.cardType === "Mastercard") {
      cardLogo = mastercardLogo;
    }

    return (
      <div
        key={card.cardNumber}
        className="flex items-center p-2 cursor-pointer "
        onClick={() => handleOptionClick(card.cardNumber)}
      >
        {cardLogo && (
          <img src={cardLogo} alt={card.cardType} className="w-8 h-5 mt-2" />
        )}
        <span>{card.cardNumber}</span>
      </div>
    );
  };
  console.log(cards);
  return (
    <div className="relative w-52">
      <div
        className="cursor-pointer p-2 border border-gray-300 rounded"
        onClick={toggleDropdown}
      >
        {selectedCard ? (
          <span>{selectedCard}</span>
        ) : (
          <span>Select a card</span>
        )}
      </div>
      {isOpen && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-b border-t-0  shadow ">
          {cards?.length > 0 ? (
            cards.map((card) => renderCardOption(card))
          ) : (
            <div className="flex items-center p-2 cursor-pointer ">
              <span>No Card Saved</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function Checkout() {
  const { state, clearCart } = useContext(GeneralContext);
  const [cartItems, setCartItems] = useState([]); // Example item format: [{ id, name, quantity, price }]
  const [selectedAddress, setSelectedAddress] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [selectedCard, setSelectedCard] = useState("");
  const navigate = useNavigate();

  // Mock data for addresses and cards, replace with your data source
  const [addresses, setAddress] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCartItems(state?.cart);
  }, [state?.cart]);
  useEffect(() => {
    state?.user?.Address && setAddress(state?.user?.Address);
    state?.user?.Cards && setCards(state?.user?.Cards);
  }, [state?.user]);

  const handleAddressChange = (e) => {
    setSelectedAddress(e.target.value);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    if (e.target.value !== "Card") setSelectedCard("");
  };

  const handleCardSelectionChange = (value) => {
    setSelectedCard(value);
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.quantity * item.price,
      0
    );
  };

  const proceedToOrder = async () => {
    const orderData = cartItems.map((item) => ({
      productId: item.id,
      productImage: item.image, // Assuming you have 'image' property in your item
      name: item.name,
      quantity: item.quantity,
      price: item.price,
      // Assuming 'storeId' and 'userId' are part of your state or props
      storeId: item.storeId, // Placeholder, replace with actual value
      userId: state?.user?.userId, // Placeholder, replace with actual value
    }));

    try {
      for (const data of orderData) {
        await pushToDatabase("UnderProcessOrders/", data);
      }
      console.log("All orders have been successfully processed.");
      // You might want to navigate to another page or show a success message here
    } catch (error) {
      console.error("Error processing orders:", error);
    }

    window.alert(
      "Your Order is Under Process. You can visit My Orders Tab to View Order Status"
    );
    clearCart();
    navigate("/myOrders");
  };

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-lg mt-8">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-semibold mb-4">Checkout</h2>
          <h3 className="text-xl font-semibold mb-2">Items</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="border px-4 py-2 text-left">Item Name</th>
                  <th className="border px-4 py-2 text-left">Quantity</th>
                  <th className="border px-4 py-2 text-left">Price</th>
                  <th className="border px-4 py-2 text-left">Total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.id}>
                    <td className="border px-4 py-2">{item.name}</td>
                    <td className="border px-4 py-2">{item.quantity}</td>
                    <td className="border px-4 py-2">${item.price}</td>
                    <td className="border px-4 py-2">
                      ${item.quantity * item.price}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-lg font-semibold">
            Total Price: ${calculateTotal()}
          </div>
          <div className="mt-4">
            <h3 className="font-semibold mb-2">Address Selection</h3>
            <select
              value={selectedAddress}
              onChange={handleAddressChange}
              className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
            >
              {addresses.length > 0 ? (
                addresses.map((address, index) => (
                  <option key={index} value={address}>
                    {address}
                  </option>
                ))
              ) : (
                <option key={0}>No Address Saved</option>
              )}
            </select>
          </div>
          <div className="mt-4">
            <h3 className="font-semibold mb-2">Payment Selection</h3>
            <label className="inline-flex items-center mr-4">
              <input
                type="radio"
                value="Cash"
                checked={paymentMethod === "Cash"}
                onChange={handlePaymentMethodChange}
                className="form-radio"
              />
              <span className="ml-2">Cash</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="Card"
                checked={paymentMethod === "Card"}
                onChange={handlePaymentMethodChange}
                className="form-radio"
              />
              <span className="ml-2">Card</span>
            </label>
            {paymentMethod === "Card" && (
              <CustomDropdown
                paymentMethod="Card"
                cards={cards}
                selectedCard={selectedCard}
                handleCardSelectionChange={handleCardSelectionChange}
              />
            )}
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={proceedToOrder}
            >
              Proceed to Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
