import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDataById, getDataByQuery } from "../dbFuntions";
import CardGrid from "../components/CardGrid";

const Class = () => {
  const { classId } = useParams();
  const [classes, setClasses] = useState(null);
  const [course, setCourse] = useState([]);

  useEffect(() => {
    // Fetch store details
    getDataById("Class/", classId)
      .then((classData) => {
        setClasses(classData);
      })
      .catch((error) => {
        console.error("Error fetching store data:", error);
      });

    // Fetch products uploaded by the store
    getDataByQuery("Course/", { orderBy: "classId", equalTo: classId })
      .then((courseData) => {
        setCourse(courseData);
      })
      .catch((error) => {
        console.error("Error fetching products data:", error);
      });
  }, [classId]);

  return (
    <div className="container mx-auto mt-8">
      {classes && (
        <div className="text-center mb-8">
          <img
            src={classes.image}
            alt={classes.className}
            className="mx-auto w-32 h-32 rounded-full mb-4"
          />
          <h2 className="text-2xl font-semibold mb-2">{classes.className}</h2>
          <p className="text-gray-600 mb-2">
            Class Description: {classes.description}
          </p>
          <div className="flex items-center justify-center mb-4">
            <div className="flex items-center">
              {[...Array(5)].map((_, index) => (
                <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 ${
                    index < classes.rating ? "text-yellow-500" : "text-gray-300"
                  }`}
                  fill={index < classes.rating ? "yellow" : "white"}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4l1.55 3.9L18 9.24l-3.3 2.54.92 4.63L12 15.4l-4.62 2.01.92-4.63L6 9.24l4.45-1.34L12 4z"
                  />
                </svg>
              ))}
              <p className="ml-2">{classes.rating}</p>
            </div>
          </div>
        </div>
      )}

      <h2 className="text-2xl font-semibold mb-4">Courses</h2>
      {course && course.length > 0 ? (
        <CardGrid data={course} gridSize={3} />
      ) : (
        <p>No courses uploaded by this class yet</p>
      )}
    </div>
  );
};

export default Class;
