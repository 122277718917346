import { Link } from "react-router-dom";

function CTA() {
  return (
    <section
      className="py-20"
      style={{
        background:
          "linear-gradient(152.92deg, rgba(255, 255, 0, 0.2) 4.54%, rgba(255, 255, 0, 0.17) 34.2%, rgba(255, 255, 0, 0.1) 77.55%)",
      }}
    >
      <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
        <div className="max-w-xl space-y-2 md:mx-auto">
          <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            Learn about art with us
          </p>
          <p className="text-gray-600">
            Get helping material and resources related to art compiled by
            professional artists. Take art lessons from the best artists.
          </p>
        </div>
        <div className="mt-4 text-center">
          <Link
            to={"/course"}
            className="inline-block mt-5 py-2 px-4 text-white font-medium bg-gray-600 duration-150 hover:bg-yellow-600 active:bg-yellow-600 rounded-lg shadow-md hover:shadow-none"
          >
            Explore Classes
          </Link>
        </div>
      </div>
    </section>
  );
}

export default CTA;
