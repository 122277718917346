import { useEffect, useRef, useState } from "react";
import CardGrid from "../components/CardGrid";
import { readFromDatabase } from "../dbFuntions";

function Dropdown({ content, onCategorySelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <button
        className="flex items-center justify-center mx-2 gap-x-1 py-2 px-4 text-black font-medium border-gray-200 border-2 duration-150 hover:bg-yellow-600 active:bg-gray-500 rounded-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        {content.title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className={`w-5 h-5 transition-transform transform ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <path
            fillRule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute top-full my-1 right-4 w-max max-w-screen-md bg-white border border-gray-200 rounded-md shadow-lg">
          <div className="max-w-screen-xl mx-auto py-4 gap-12 text-gray-600">
            {content.items.map((item, index) => (
              <div key={index} className="relative ">
                <span
                  className="flex items-center justify-start gap-x-1 py-2 px-4 text-black w-full font-medium duration-150 hover:bg-yellow-300 active:bg-gray-500  md:inline-flex cursor-pointer"
                  onClick={() => {
                    setIsOpen(false); // Close the dropdown
                    onCategorySelect(item); // Update the selected category
                  }}
                >
                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function ProductNavigationBar({ position, onCategorySelect }) {
  const categoriesContent = {
    title: "Categories",
    items: [
      "None",
      "Paintings",
      "Sculptures",
      "Prints",
      "Photography",
      "Drawings and Illustrations",
      "Crafts and Handmade Items",
      "Art Supplies",
      "Art Books and Magazines",
    ],
  };

  const handleCategorySelect = (category) => {
    onCategorySelect(category);
    // You can add any other actions you want to perform when a category is selected
  };
  return (
    <nav className="bg-white">
      <div className="flex items-center justify-between pt-3 px-4 max-w-screen-xl mx-auto md:px-8">
        <span className="text-gray-500 text-sm mt-1">{position}</span>
        <div className="flex px-2">
          <Dropdown
            content={categoriesContent}
            onCategorySelect={handleCategorySelect}
          />
        </div>
      </div>
    </nav>
  );
}

function Explore() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("None");
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    readFromDatabase("Products/")
      .then((products) => {
        if (products) {
          const productsArray = Object.keys(products).map((productId) => ({
            productId,
            ...products[productId],
          }));
          setProducts(productsArray);
          setIsLoading(false);
        } else {
          console.log("products not found.");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error finding products:", error);
      });
  }, []);
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    // You can add any other actions you want to perform when a category is selected
  };
  useEffect(() => {
    const filtered =
      selectedCategory === "" || selectedCategory === "None"
        ? products
        : products.filter((product) => product.category === selectedCategory);

    setData(filtered);
  }, [selectedCategory, products]);
  console.log(selectedCategory);
  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
          <div className="w-16 h-16 border-t-4 border-b-4 border-yellow-500 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div>
          <ProductNavigationBar
            onCategorySelect={handleCategorySelect}
            position={`Explore ${
              selectedCategory === "None" ? "" : "> " + selectedCategory
            }`}
          />
          {data.length > 0 ? (
            <CardGrid data={data} gridSize={4} />
          ) : (
            <div className="my-10 text-center w-full">No Products Found</div>
          )}
        </div>
      )}
    </>
  );
}

export default Explore;
