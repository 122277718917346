import { useEffect, useState } from "react";
import CardGrid from "../components/CardGrid";
import { readFromDatabase } from "../dbFuntions";

function Course() {
  const [courses, setCourses] = useState([]);
  const [topCourses, setTopCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("None");
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    setIsLoading(true);
    readFromDatabase("Course/")
      .then((products) => {
        if (products) {
          const productsArray = Object.keys(products).map((productId) => ({
            productId,
            ...products[productId],
          }));
          setCourses(productsArray);
          setIsLoading(false);
        } else {
          console.log("Course not found.");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error finding Course:", error);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    if (courses.length) {
      // Sort courses by rating in descending order and slice the top 4
      const sortedCourses = [...courses]
        .sort((a, b) => b.rating - a.rating)
        .slice(0, 4);
      setTopCourses(sortedCourses);
    }
  }, [courses]);

  useEffect(() => {
    let filtered = courses;

    // Filter by category
    if (selectedCategory !== "None") {
      filtered = filtered.filter(
        (course) => course.category === selectedCategory
      );
    }

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter((course) =>
        course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setData(filtered);
  }, [selectedCategory, courses, searchTerm]);

  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
          <div className="w-16 h-16 border-t-4 border-b-4 border-yellow-500 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="bg-white flex">
          <div className="max-w-screen-xl mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              Short Courses
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Upgrade your skills with our short and focused courses.
            </p>
            {/* Add banner here */}
            <div className="bg-gradient-to-b from-yellow-400 to-yellow-100 rounded-lg shadow-lg p-6">
              <h1 className="text-4xl font-bold text-white mb-4">
                Featured Courses
              </h1>
              {topCourses && <CardGrid data={topCourses} gridSize={4} />}
            </div>
            {/* Add two-column layout */}
            <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mt-8">
              {/* Left column */}
              <div className="md:col-span-1 bg-white rounded-lg shadow-md p-6">
                {/* Search bar and options */}
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Search"
                    className="border rounded px-3 py-2 w-full focus:outline-none"
                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on input change
                  />
                </div>
                <div>
                  <ul className="list-disc pl-5">
                    {[
                      "None",
                      "Paintings",
                      "Sculptures",
                      "Photography",
                      "Drawings and Illustrations",
                    ].map((category) => (
                      <li
                        key={category}
                        className="cursor-pointer hover:text-blue-500"
                        onClick={() => setSelectedCategory(category)}
                      >
                        {category}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* Right column */}
              <div className="md:col-span-4 bg-white rounded-lg shadow-md p-6">
                {/* Cards or other content */}
                {data.length > 0 ? (
                  <CardGrid data={data} gridSize={3} />
                ) : (
                  <div className="my-10 text-center w-full">
                    No Products Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Course;
