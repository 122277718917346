import React, { useContext, useEffect, useState } from "react";
import { getAllReviews, pushToDatabase, writeToDatabase } from "../dbFuntions";
import { Context as GeneralContext } from "../context/GeneralContext";

const ReviewsComponent = ({ screen, id }) => {
  const screens = screen ? screen : "";

  const { state } = useContext(GeneralContext);
  const [reviewText, setReviewText] = useState("");
  const [selectedStars, setSelectedStars] = useState(0);
  const [reviews, setReviews] = useState([]);
  const postId = id;
  useEffect(() => {
    // Fetch all reviews
    if (screens === "ItemDetail") {
      getAllReviews("Reviews/")
        .then((reviews) => {
          if (reviews) {
            // Filter reviews based on the product ID
            const filteredReviews = Object.values(reviews).filter(
              (review) => review.productid === postId.id
            );
            const totalRating = filteredReviews.reduce(
              (acc, review) => acc + review.stars,
              0
            );
            const averageRating =
              filteredReviews.length > 0
                ? totalRating / filteredReviews.length
                : 0;
            writeToDatabase(`Products/${postId.id}/rating/`, averageRating);
            setReviews(filteredReviews);
          } else {
            console.log("No reviews available.");
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    } else if (screens === "ClassDescription") {
      getAllReviews("ClassReviews/")
        .then((reviews) => {
          if (reviews) {
            // Filter reviews based on the product ID
            const filteredReviews = Object.values(reviews).filter(
              (review) => review.productid === postId
            );
            const totalRating = filteredReviews.reduce(
              (acc, review) => acc + review.stars,
              0
            );
            const averageRating =
              filteredReviews.length > 0
                ? totalRating / filteredReviews.length
                : 0;
            writeToDatabase(`Course/${postId}/rating/`, averageRating);
            setReviews(filteredReviews);
          } else {
            console.log("No reviews available.");
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    }
  }, [postId]);

  const handleReviewSubmit = async () => {
    const newReview = {
      text: reviewText,
      stars: selectedStars,
      // For simplicity, let's add a dummy user for now
      username: state?.user?.username,
      // Add user image URL here
      userImage: state?.user?.profilePicture,
      productid: postId?.id ? postId.id : postId,
      // Add more properties like date and time if needed
      date: new Date().toLocaleString(),
    };
    if (state?.user && screens === "ItemDetail") {
      await pushToDatabase("Reviews/", newReview);

      setReviews([newReview, ...reviews]);
      setReviewText("");
      setSelectedStars(0);
    } else if (state?.user && screens === "ClassDescription") {
      await pushToDatabase("ClassReviews/", newReview);

      setReviews([newReview, ...reviews]);
      setReviewText("");
      setSelectedStars(0);
    } else {
      window.alert("Please sign in first to submit a review.");
      setReviewText("");
      setSelectedStars(0);
    }
  };

  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-bold mb-4">Write a Review</h2>
      <div className="mb-4">
        {/* Review Selector */}
        <span>
          {[1, 2, 3, 4, 5].map((star, index) => (
            <span
              key={index}
              onClick={() => setSelectedStars(star)}
              style={{
                cursor: "pointer",
                color: star <= selectedStars ? "gold" : "gray",
              }}
            >
              ★
            </span>
          ))}
        </span>
      </div>
      {/* Input for review text */}
      <div className="flex mb-4">
        <textarea
          className="flex-1 p-2 border border-gray-300 rounded mr-2"
          style={{ width: "90%" }}
          value={reviewText}
          onChange={(e) => setReviewText(e.target.value)}
          placeholder="Write your review here..."
        />
        <button
          className="lg:w-1/6 sm:1/12 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleReviewSubmit}
        >
          Submit
        </button>
      </div>
      {/* List of Reviews */}
      <div className="mt-8 overflow-auto max-h-96">
        <h3 className="text-2xl font-bold mb-4">Latest Reviews</h3>
        {reviews.map((review, index) => (
          <div key={index} className="flex items-start mb-4">
            {/* User Image */}
            <img
              src={review.userImage}
              alt={review.username}
              className="w-12 h-12 rounded-full mr-4 "
            />
            {/* User Name */}
            <div className="flex flex-col flex-1">
              <p className="text-lg font-bold">{review.username}</p>
              {/* Stars */}
              <div className="flex items-center">
                {[1, 2, 3, 4, 5].map((star, index) => (
                  <span
                    key={index}
                    style={{ color: star <= review.stars ? "gold" : "gray" }}
                  >
                    ★
                  </span>
                ))}
              </div>
              {/* Review Text */}
              <p className="mt-2 text-justify">{review.text}</p>
              {/* Date/Time */}
              <p className="text-xs text-gray-500 mt-2">{review.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewsComponent;
