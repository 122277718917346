// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Import getStorage from firebase/storage

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAMlPkmsdolL_P0LYaGgB_sGG8Pp5C9eVk",
  authDomain: "arthub-33dc6.firebaseapp.com",
  databaseURL: "https://arthub-33dc6-default-rtdb.firebaseio.com",
  projectId: "arthub-33dc6",
  storageBucket: "arthub-33dc6.appspot.com",
  messagingSenderId: "867961553956",
  appId: "1:867961553956:web:7a184957473adf931c04b1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { app, auth, db, storage };
