import { Link, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { getDataById } from "../dbFuntions";
import ReviewsComponent from "../components/Reviews";
import { Context as GeneralContext } from "../context/GeneralContext";

function ItemDetail() {
  const postId = useParams();
  const { state, addToCart } = useContext(GeneralContext);

  // State for managing selected image index
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  // State for managing quantity selection
  const [quantity, setQuantity] = useState(1);

  const [product, setProduct] = useState({
    img: [], // Initialize img as an empty array
  });

  const [store, setStore] = useState(null);

  useEffect(() => {
    getDataById("Products/", postId.id)
      .then((details) => {
        setProduct(details);

        getDataById("Store/", details.storeId)
          .then((storeInfo) => {
            setStore(storeInfo);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [postId]);

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    // Ensure the new quantity is at least 1
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
    } else {
      // If the new quantity is less than 1, set it to 1
      setQuantity(1);
    }
  };

  const handleAddToCart = () => {
    if (state?.user) {
      const id = postId.id;
      const name = product.productName;
      const image = product.images[0];
      const quantityOfTheProduct = quantity;
      const price = product.price;
      const storeId = product.storeId;
      addToCart(id, name, image, quantityOfTheProduct, price, storeId);
      window.alert("Item added to cart.");
    } else {
      window.alert("Please sign in first to add to cart.");
    }
  };

  const handleNextImage = () => {
    const newIndex = (selectedImageIndex + 1) % product.images.length;
    setSelectedImageIndex(newIndex);
  };

  const handlePreviousImage = () => {
    const newIndex =
      (selectedImageIndex - 1 + product.images.length) % product.images.length;
    setSelectedImageIndex(newIndex);
  };

  return (
    <div>
      <div className="lg:flex sm:md:flex-row justify-center mt-5">
        <div className="lg:w-1/3 sm:md:w-full h-96 border-2 border-gray-400 rounded-xl overflow-hidden relative">
          {product.images && product.images.length > 0 && (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              src={product.images[selectedImageIndex]}
              alt={`Product Image ${selectedImageIndex + 1}`}
              className="w-full h-full object-contain rounded-t-md"
              onClick={handleNextImage}
            />
          )}
          <div
            className="absolute top-0 bottom-0 left-0 flex justify-center items-center cursor-pointer"
            onClick={handlePreviousImage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-14 w-14 text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div
            className="absolute top-0 bottom-0 right-0 flex justify-center items-center cursor-pointer"
            onClick={handleNextImage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-14 w-14 text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
        <div className="lg:w-1/2 sm:md:w-full p-4">
          <h2 className="text-xl font-bold mb-2">{product.productName}</h2>
          <p className="text-lg font-bold my-2">Price: £{product.price}</p>
          <div className="flex items-center my-2">
            {/* Display the star rating */}
            <div className="flex items-center">
              {[...Array(5)].map((_, index) => (
                <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 ${
                    index < product.rating ? "text-yellow-500" : "text-gray-300"
                  }`}
                  fill={index < product.rating ? "yellow" : "white"}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4l1.55 3.9L18 9.24l-3.3 2.54.92 4.63L12 15.4l-4.62 2.01.92-4.63L6 9.24l4.45-1.34L12 4z"
                  />
                </svg>
              ))}
              <span className="ml-2">
                {product.rating && product?.rating.toFixed(1)}
              </span>
            </div>
          </div>
          <div className="flex items-center my-2">
            <input
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              className="w-16 border border-gray-400 rounded p-2 "
            />
          </div>
          <div className="flex items-center my-2">
            <button
              onClick={handleAddToCart}
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Add to Cart
            </button>
          </div>
          {/* Display store profile picture and name */}
          {store && (
            <Link
              to={`/store/${product.storeId}`}
              className="flex items-center mb-2"
            >
              <p className="text-lg font-bold my-2">Store: </p>
              <img
                src={store.image}
                alt="Store Profile"
                className="w-10 h-10 rounded-full mr-2"
              />
              <span className="text-lg font-semibold">{store.storeName}</span>
            </Link>
          )}
          <p className="text-gray-700 mb-4 text-justify">
            {product.description}
          </p>
        </div>
      </div>
      <ReviewsComponent screen={"ItemDetail"} id={postId} />
    </div>
  );
}

export default ItemDetail;
