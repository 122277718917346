import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Explore from "./pages/Explore";
import Cart from "./pages/Cart";
import Dashboard from "./pages/Dashboard";
import Post from "./pages/Post";
import ProductDesc from "./pages/ProductDesc";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Layout from "./components/Layout";
import ItemDetail from "./pages/ItemDetail";
import Course from "./pages/Course";
import StoreForm from "./pages/StoreForm";
import ClassForm from "./pages/ClassForm";
import ProductForm from "./pages/ProductForm";
import CourseForm from "./pages/CourseForm";
import ForgotPassword from "./pages/ForgotPassword";
import { Context, Provider } from "./context/GeneralContext";
import { useContext } from "react";
import EditProductForm from "./pages/EditProductForm";
import ClassDescription from "./pages/ClassDescription";
import EditClassForm from "./pages/EditClassForm";
import Store from "./pages/Store";
import Class from "./pages/Class";
import Checkout from "./pages/CheckOut";
import MyOrders from "./pages/MyOrders";

function App() {
  const { state } = useContext(Context);
  const userId = state?.user?.userId;
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/explore" element={<Explore />} />
        <Route path="/cart" element={userId ? <Cart /> : <SignIn />} />
        <Route
          path="/dashboard"
          element={userId ? <Dashboard /> : <SignIn />}
        />
        <Route path="/myOrders" element={userId ? <MyOrders /> : <SignIn />} />
        <Route path="/add-post" element={<Post />} />
        <Route path="/product-description" element={<ProductDesc />} />
        <Route path="/profile" element={userId ? <Profile /> : <SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/course" element={<Course />} />
        <Route path="/ItemDetail/:id" element={<ItemDetail />} />
        <Route
          path="/EditProductForm/:id"
          element={userId ? <EditProductForm /> : <SignIn />}
        />
        <Route
          path="/StoreForm"
          element={userId ? <StoreForm /> : <SignIn />}
        />
        <Route
          path="/ClassForm"
          element={userId ? <ClassForm /> : <SignIn />}
        />
        <Route
          path="/ProductForm"
          element={userId ? <ProductForm /> : <SignIn />}
        />
        <Route
          path="/CourseForm"
          element={userId ? <CourseForm /> : <SignIn />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/class/:classId" element={<ClassDescription />} />
        <Route path="/EditClassForm/:id" element={<EditClassForm />} />
        <Route path="/store/:storeId" element={<Store />} />
        <Route path="/classpage/:classId" element={<Class />} />
        <Route path="/checkout" element={userId ? <Checkout /> : <SignIn />} />
      </Routes>
    </Layout>
  );
}

export default () => {
  return (
    <Provider>
      <App />
    </Provider>
  );
};
