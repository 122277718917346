import {
  query,
  orderByChild,
  equalTo,
  ref,
  set,
  get,
  child,
  push,
  remove,
} from "firebase/database";
import { db } from "./firebase";

// Function to write data to the database
export const writeToDatabase = async (path, data) => {
  try {
    await set(ref(db, path), data);
    console.log("Data successfully written to the database");
  } catch (error) {
    console.error("Error writing data to the database:", error);
  }
};
export const pushToDatabase = async (path, data) => {
  try {
    await push(ref(db, path), data);
    console.log("Data successfully push to the database");
  } catch (error) {
    console.error("Error writing data to the database:", error);
  }
};

// Function to read data from the database
export const readFromDatabase = async (path) => {
  try {
    const snapshot = await get(ref(db, path));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available at the specified path");
      return null;
    }
  } catch (error) {
    console.error("Error reading data from the database:", error);
    return null;
  }
};

export const findUser = async (path, emailToFind) => {
  try {
    const snapshot = await get(ref(db, path));
    if (snapshot.exists()) {
      const data = snapshot.val();
      // Iterate through the user details to find the one with the matching email
      for (const userId in data) {
        const userDetails = data[userId];

        if (userDetails.email === emailToFind) {
          userDetails.userId = userId;
          return userDetails;
        }
      }
      console.log("User with email not found");
      return null;
    } else {
      console.log("No data available at the specified path");
      return null;
    }
  } catch (error) {
    console.error("Error reading data from the database:", error);
    return null;
  }
};

export const findStore = async (path, userId) => {
  try {
    const snapshot = await get(ref(db, path));
    if (snapshot.exists()) {
      const data = snapshot.val();
      // Iterate through the user details to find the one with the matching email
      for (const storeId in data) {
        const storeDetail = data[storeId];
        if (storeDetail.userId === userId) {
          storeDetail.storeId = storeId;
          return storeDetail;
        }
      }
      console.log("Store with id not found");
      return null;
    } else {
      console.log("No data available at the specified path");
      return null;
    }
  } catch (error) {
    console.error("Error reading data from the database:", error);
    return null;
  }
};

export const findCourse = async (path, classId) => {
  try {
    const snapshot = await get(ref(db, path));
    if (snapshot.exists()) {
      const data = snapshot.val();
      const matchingCourses = [];
      // Iterate through the user details to find the one with the matching email
      for (const courseId in data) {
        const courseDetail = data[courseId];
        if (courseDetail.classId === classId) {
          courseDetail.courseId = courseId;
          matchingCourses.push(courseDetail); // Add matching course to array
        }
      }
      if (matchingCourses.length === 0) {
        console.log("No course found with the specified classId");
        return null;
      }
      return matchingCourses;
    } else {
      console.log("No data available at the specified path");
      return null;
    }
  } catch (error) {
    console.error("Error reading data from the database:", error);
    return null;
  }
};

export const findProduct = async (path, storeId) => {
  try {
    const snapshot = await get(ref(db, path));
    if (snapshot.exists()) {
      const data = snapshot.val();

      const matchingProduct = [];
      // Iterate through the user details to find the one with the matching email
      for (const productId in data) {
        const productDetail = data[productId];
        if (productDetail.storeId === storeId) {
          productDetail.productId = productId;
          matchingProduct.push(productDetail); // Add matching course to array
        }
      }
      if (matchingProduct.length === 0) {
        console.log("No course found with the specified classId");
        return null;
      }
      return matchingProduct;
    } else {
      console.log("No data available at the specified path");
      return null;
    }
  } catch (error) {
    console.error("Error reading data from the database:", error);
    return null;
  }
};
// Function to get information corresponding to a specific ID
export const getDataById = async (path, id) => {
  try {
    const snapshot = await get(child(ref(db, path), id));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available for the specified ID");
      return null;
    }
  } catch (error) {
    console.error("Error getting data by ID from the database:", error);
    return null;
  }
};

export const removeFromUnderProcessOrders = async (orderId) => {
  await remove(ref(db, `UnderProcessOrders/${orderId}`));
};

// Function to add an order to CompletedOrders
export const addToCompletedOrders = async (orderId, orderData) => {
  await set(ref(db, `CompletedOrders/${orderId}`), orderData);
};

export const getDataByQuery = async (path, queryParams) => {
  const { orderBy, equalTo: equalToValue } = queryParams;

  try {
    const dbRef = ref(db, path);
    const q = query(dbRef, orderByChild(orderBy), equalTo(equalToValue));
    const snapshot = await get(q);

    const data = {};
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        data[childSnapshot.key] = childSnapshot.val();
      });
    }

    const matchingProduct = [];
    // Iterate through the user details to find the one with the matching email
    for (const productId in data) {
      const productDetail = data[productId];
      productDetail.productId = productId;
      matchingProduct.push(productDetail); // Add matching course to array
    }

    return matchingProduct;
  } catch (error) {
    console.error("Error getting data by query from the database:", error);
    return null;
  }
};

// Function to get class details by ID
export const getClassById = async (classId) => {
  try {
    const snapshot = await get(ref(db, `/Course/${classId}`));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No class found with the specified ID");
      return null;
    }
  } catch (error) {
    console.error("Error getting class details from the database:", error);
    return null;
  }
};

// Function to get feedback for a class by ID
export const getFeedbackByClassId = async (classId) => {
  try {
    const snapshot = await get(ref(db, `/Feedback/${classId}`));
    if (snapshot.exists()) {
      return Object.values(snapshot.val()); // Convert feedback object to array
    } else {
      console.log("No feedback available for the specified class ID");
      return [];
    }
  } catch (error) {
    console.error("Error getting feedback from the database:", error);
    return [];
  }
};
export const getAllReviews = async (path) => {
  try {
    const snapshot = await get(ref(db, path));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available for the specified path:", path);
      return null;
    }
  } catch (error) {
    console.error("Error getting data from the database:", error);
    return null;
  }
};
