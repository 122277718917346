import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Context as AuthContext } from "../context/GeneralContext";

// Import eye icons from an image source
import EyeHide from "../images/hide.png";
import EyeView from "../images/view.png";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [signInError, setSignInError] = useState("");
  const { signIn } = useContext(AuthContext);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [eyeIcon, setEyeIcon] = useState(EyeHide); // State for toggling eye icon

  const navigate = useNavigate("");

  useEffect(() => {
    // On component mount, check if rememberMe is true and restore email from local storage
    const storedEmail = localStorage.getItem("rememberedEmail");
    if (storedEmail) {
      setEmail(storedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleRememberMeChange = () => {
    // Toggle rememberMe state
    setRememberMe(!rememberMe);
  };

  const togglePasswordVisibility = () => {
    // Toggle the state for showing/hiding the password
    setShowPassword(!showPassword);
    // Toggle eye icon based on the state
    setEyeIcon(showPassword ? EyeHide : EyeView);
  };

  const signInprocess = (e) => {
    e.preventDefault();

    const callback = () => {
      setIsSigningIn(true);
      setTimeout(() => {
        setIsSigningIn(false);
        navigate("/");
      }, 800);
    };
    signIn({ email, password, setSignInError, rememberMe, callback });
  };

  return (
    <main className="w-full h-screen flex flex-col items-center justify-center px-4">
      <div className="max-w-sm w-full text-gray-600 space-y-5">
        <div className="text-center pb-8">
          <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">
            Sign in to your account
          </h3>
        </div>
        <form onSubmit={signInprocess} className="space-y-5">
          <div>
            <label className="font-medium">Email</label>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
            />
          </div>
          <div>
            <label className="font-medium">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle password visibility
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              >
                <img src={eyeIcon} alt="eye" className="h-6 w-6 text-gray-400" />
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between text-sm">
            <label
              htmlFor="remember-me-checkbox"
              className="flex items-center gap-x-3 cursor-pointer"
            >
              <input
                type="checkbox"
                id="remember-me-checkbox"
                className="checkbox-item"
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
              <span>Remember me</span>
            </label>
            <Link
              to="/forgot-password"
              className="text-center text-yellow-600 hover:text-yellow-500"
            >
              Forgot password?
            </Link>
          </div>
          <div className="text-center">
            {signInError && <p className="text-red-600">{signInError}</p>}
          </div>
          {isSigningIn ? (
            <div className="w-full px-4 py-2 text-white font-medium bg-yellow-600 hover:bg-yellow-500 active:bg-yellow-600 rounded-lg duration-150 text-center">
              Loading
            </div>
          ) : (
            <button className="w-full px-4 py-2 text-white font-medium bg-yellow-600 hover:bg-yellow-500 active:bg-yellow-600 rounded-lg duration-150">
              Sign in
            </button>
          )}
        </form>

        <p className="text-center">
          Don't have an account?{" "}
          <Link
            to="/signup"
            className="font-medium text-yellow-600 hover:text-yellow-500"
          >
            Sign up
          </Link>
        </p>
      </div>
    </main>
  );
}

export default SignIn;
