import { useState, useRef, useEffect, useContext } from "react";
import arthubLogo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Context as GeneralContext } from "../context/GeneralContext";
import emptyProfile from "../images/empty-profile-picture.png";

const ProfileDropDown = (props) => {
  const [buttonState, setbuttonState] = useState(false);
  const { state, logout } = useContext(GeneralContext);
  const profileRef = useRef();
  const navigate = useNavigate();
  console.log(props);
  const navigation = [
    { title: "Profile", path: "/profile" },
    { title: "Dashboard", path: "/dashboard" },
    { title: "My Orders", path: "/myOrders" },
    { title: "Log out", path: "/", onClick: logout },
  ];

  useEffect(() => {
    const handleDropDown = (e) => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setbuttonState(false);
      }
    };

    if (profileRef.current) {
      document.addEventListener("click", handleDropDown);
    }

    return () => {
      document.removeEventListener("click", handleDropDown);
    };
  }, []);
  return (
    <div className={`relative ${props.class}`}>
      <div className="flex items-center space-x-4">
        <button
          ref={profileRef}
          className="w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 ring-2 lg:focus:ring-yellow-600 "
          onClick={() => setbuttonState(!buttonState)}
        >
          <img
            src={
              state?.user?.profilePicture
                ? state.user.profilePicture
                : emptyProfile
            }
            className="w-full h-full rounded-full"
            alt=""
          />
        </button>
      </div>
      <ul
        className={`bg-white top-2 right-0 mt-5 space-y-5 absolute border rounded-md text-sm w-52 shadow-md lg:space-y-0 lg:mt-0 ${
          buttonState ? "" : "hidden"
        } z-10`}
      >
        {navigation.map((item, idx) => (
          <li key={idx}>
            {" "}
            {/* Added key prop */}
            {item.onClick ? (
              <button
                className="block text-gray-600 hover:text-yellow-600 hover:bg-gray-50 px-2.5 py-1 w-full justify-start flex"
                onClick={() => {
                  item.onClick();
                  props.setMenuState(false);
                  navigate("/");
                }}
              >
                {item.title}
              </button>
            ) : (
              <Link
                className="block text-gray-600  hover:text-yellow-600 hover:bg-gray-50 px-2.5 py-1"
                to={item.path}
                onClick={() => props.setMenuState(false)}
              >
                {item.title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

function NavigationBar() {
  const [menuState, setMenuState] = useState(false);
  const { state } = useContext(GeneralContext);
  const navigation = [
    { title: "Home", path: "/" },
    { title: "Explore", path: "/explore" },
    { title: "Course", path: "/course" },
    ...(state.user !== null ? [{ title: "Cart", path: "/cart" }] : []),
  ];
  const navigate = useNavigate();

  return (
    <nav className="bg-white border-b">
      <div className="flex items-center space-x-8 py-3 px-4 max-w-screen-xl mx-auto md:px-8">
        <div className="flex-none lg:flex-initial">
          <button onClick={() => navigate("/")}>
            <img src={arthubLogo} width={60} height={30} alt="Arthub logo" />
          </button>
        </div>
        <div className="flex-1 flex items-center justify-between">
          <div
            className={`bg-white absolute z-20 w-full top-10 left-0 p-4 border-b lg:static lg:block lg:border-none ${
              menuState ? "" : "hidden"
            }`}
          >
            <ul className="mt-12 space-y-5 lg:flex lg:space-x-6 lg:space-y-0 lg:mt-0">
              {navigation.map((item, idx) => (
                <li key={idx} className="text-gray-600 hover:text-yellow-600">
                  <Link to={item.path} onClick={() => setMenuState(false)}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {state.user !== null ? (
            <div className="flex-1 flex items-center justify-end space-x-2 sm:space-x-6">
              <button
                className="z-30 outline-none text-gray-400 block lg:hidden"
                onClick={() => setMenuState(!menuState)}
              >
                {menuState ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 12h16m-7 6h7"
                      />
                    </svg>
                  </>
                )}
              </button>
              <ProfileDropDown
                class={` z-30 lg:block ml-5`}
                setMenuState={setMenuState}
              />
            </div>
          ) : (
            <div className="flex-1 flex items-center justify-end space-x-2 sm:space-x-6">
              <button
                className="z-30 outline-none text-gray-400 block lg:hidden"
                onClick={() => setMenuState(!menuState)}
              >
                {menuState ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
