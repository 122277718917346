import React, { useContext, useEffect, useState } from "react";
import { getDataById, writeToDatabase } from "../dbFuntions";
import { Context as GeneralContext } from "../context/GeneralContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function EditProductForm() {
  const postId = useParams();
  const { state, getProduct } = useContext(GeneralContext);
  const [product, setProduct] = useState({
    img: [], // Initialize img as an empty array
  });

  useEffect(() => {
    getDataById("Products/", postId.id)
      .then((details) => {
        setProduct(details);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [postId]);

  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [rating, setRating] = useState(0);
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState("");
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const storeId = state.store.storeId;

  useEffect(() => {
    setProductName(product?.productName);
    setDescription(product?.description);
    setPrice(product?.price);
    setRating(product?.rating);
    setImages(product?.images);
    setCategory(product?.category);
  }, [product]);

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleImageChange = async (e) => {
    try {
      setIsUpdatingImage(true);
      const fileList = Array.from(e.target.files);

      // Filter out non-image files based on file extensions
      const imageFiles = fileList.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith(".jpg") ||
          fileName.endsWith(".jpeg") ||
          fileName.endsWith(".png")
        );
      });

      if (imageFiles.length === 0) {
        // No image files selected, handle error or notify the user
        console.error("No image files selected");
        setErrorMessage("Please select only image files (jpg, jpeg, png, gif)");
        return;
      }

      const uploadTasks = imageFiles.map(async (file) => {
        const storageRef = ref(storage, `products_images/${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
      });

      const downloadURLs = await Promise.all(uploadTasks);

      setImages(downloadURLs);
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setIsUpdatingImage(false);
    }
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    writeToDatabase(`Products/${postId.id}/`, {
      productName,
      description,
      images,
      rating,
      category,
      price,
      storeId,
    });
    // Reset form fields after submission
    setProductName("");
    setDescription("");
    setPrice("");
    setImages([]);
    getProduct({ storeId });
    navigate("/dashboard");
  };

  const categoryOptions = [
    "Paintings",
    "Sculptures",
    "Prints",
    "Photography",
    "Drawings and Illustrations",
    "Crafts and Handmade Items",
    "Art Supplies",
    "Art Books and Magazines",
  ];

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-lg mt-8">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-semibold mb-4">Edit Product</h2>
          <form onSubmit={handleSubmit}>
            {isUpdatingImage && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
                <div className="w-16 h-16 border-t-4 border-b-4 border-yellow-500 rounded-full animate-spin"></div>
              </div>
            )}
            <div className="mb-4">
              <label
                htmlFor="productName"
                className="block text-sm font-medium text-gray-700"
              >
                Product Name
              </label>
              <input
                type="text"
                id="productName"
                value={productName}
                onChange={handleProductNameChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={handleDescriptionChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                rows="4"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700"
              >
                Price
              </label>
              <input
                type="number"
                id="price"
                value={price}
                onChange={handlePriceChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="images"
                className="block text-sm font-medium text-gray-700"
              >
                Product Images
              </label>
              {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
              )}
              <input
                type="file"
                id="images"
                onChange={handleImageChange}
                accept="image/*"
                multiple
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700"
              >
                Category
              </label>
              <select
                id="category"
                value={category}
                onChange={handleCategoryChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              >
                <option value="">Select a category</option>
                {categoryOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Edit Product
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditProductForm;
