import React, { useContext, useState } from "react";
import { pushToDatabase } from "../dbFuntions";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import { Context as GeneralContext } from "../context/GeneralContext";

function StoreForm() {
  const { state, getStore } = useContext(GeneralContext);
  const [storeName, setStoreName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);
  const [rating, setRating] = useState(0);
  const userId = state.user.userId;
  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      setIsUpdatingImage(true);
      // Upload the image to Firebase Cloud Storage
      const storageRef = ref(storage, `store_images/${file.name}`);
      await uploadBytes(storageRef, file);

      // Retrieve the download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageRef);

      setImage(downloadURL);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUpdatingImage(false); // Set loader to false when image update ends
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., sending data to backend
    pushToDatabase("Store/", {
      storeName,
      description,
      image,
      rating,
      userId,
    });
    // Reset form fields after submission
    setStoreName("");
    setDescription("");
    setImage(null);
    getStore({ userId });
    navigate("/dashboard");
  };

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-lg mt-8">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-semibold mb-4">Create a Store</h2>
          <form onSubmit={handleSubmit}>
            {isUpdatingImage && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
                <div className="w-16 h-16 border-t-4 border-b-4 border-yellow-500 rounded-full animate-spin"></div>
              </div>
            )}
            <div className="mb-4">
              <label
                htmlFor="storeName"
                className="block text-sm font-medium text-gray-700"
              >
                Store Name
              </label>
              <input
                type="text"
                id="storeName"
                value={storeName}
                onChange={handleNameChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={handleDescriptionChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                rows="4"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                htmlFor="image"
                className="block text-sm font-medium text-gray-700"
              >
                Store Profile Picture
              </label>
              <input
                type="file"
                id="image"
                onChange={handleImageChange}
                accept="image/*"
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create Store
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StoreForm;
