import { useState } from "react";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { push, ref, query, orderByChild, equalTo, get } from "firebase/database";
import { useNavigate, Link } from "react-router-dom";
import EyeHide from "../images/hide.png";
import EyeView from "../images/view.png";

// Function to check if username already exists
const checkUsernameExists = async (username) => {
  try {
    const usersRef = ref(db, 'userdetails');
    const usernameQuery = query(usersRef, orderByChild('username'), equalTo(username));
    const snapshot = await get(usernameQuery);
    return snapshot.exists();
  } catch (error) {
    console.error('Error checking username:', error);
    throw error; // Propagate the error upwards for handling
  }
};

function UserSignUp() {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const navigate = useNavigate("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signUp = async(e) => {
    e.preventDefault();

    // Check if username already exists
    try {
      const usernameExists = await checkUsernameExists(username);
      if (usernameExists) {
        setMessage("Username already in use. Please choose another.");
        setMessageColor("text-red-600");
        return;
      }

      if (password !== confirmPassword) {
        setMessage("Passwords do not match.");
        setMessageColor("text-red-600");
        return;
      }

      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          const userData = {
            name,
            username,
            email,
            password,
            phone: phoneNumber,
            address,
            account: accountNumber,
            profilePicture: profilePicture,
          };
          // Store user details in Realtime Database with generated ID
          push(ref(db, `userdetails/`), userData)
            .then(() => {
              setMessage("Registration successful!");
              setMessageColor("text-green-600");
              setTimeout(() => {
                setMessage("");
                setMessageColor("");
                navigate("/signin");
              }, 3000);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              setMessage("Failed to register user.");
              setMessageColor("text-red-600");
            });
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "auth/email-already-in-use") {
            setMessage("Email already in use. Registration failed.");
          }
          else if (error.code === "auth/weak-password"){
            setMessage("Password should be at least 6 characters.");
          }
          else {
            setMessage("Failed to register user.");
          }
          setMessageColor("text-red-600");
        });
    } catch (error) {
      console.error("Error checking username:", error);
      setMessage("Failed to check username availability.");
      setMessageColor("text-red-600");
    }
  };
  
  return (
    <form onSubmit={signUp} className="mt-8 space-y-5">
      <div>
        <label className="font-medium">
          Name<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
        />
      </div>
      <div>
        <label className="font-medium">
          Username<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
        />
      </div>
      <div>
        <label className="font-medium">
          Email<span className="text-red-500">*</span>
        </label>
        <input
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
        />
      </div>
      <div>
        <label className="font-medium">
          Password<span className="text-red-500">*</span>
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          >
            <img src={showPassword ? EyeView : EyeHide} alt="eye" className="h-6 w-6 text-gray-400" />
          </button>
        </div>
      </div>
      <div>
        <label className="font-medium">
          Confirm Password<span className="text-red-500">*</span>
        </label>
        <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
          />
          <button
            type="button"
            onClick={toggleConfirmPasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          >
            <img src={showConfirmPassword ? EyeView : EyeHide} alt="eye" className="h-6 w-6 text-gray-400" />
          </button>
        </div>
      </div>
      <div>
        <label className="font-medium">
          Phone Number<span className="text-red-500">*</span>
        </label>
        <input
          type="tel"
          required
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
        />
      </div>
      <div>
        <label className="font-medium">
          Address<span className="text-gray-400"> (optional)</span>
        </label>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
        />
      </div>
      <div>
        <label className="font-medium">
          Account Number<span className="text-gray-400"> (optional)</span>
        </label>
        <input
          type="text"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-yellow-600 shadow-sm rounded-lg"
        />
      </div>

      <div className="text-center">
        <div className={`${messageColor} font-medium`}>{message}</div>
      </div>
      <button className="w-full px-4 py-2 text-white font-medium bg-yellow-600 hover:bg-yellow-500 active:bg-yellow-600 rounded-lg duration-150">
        Sign Up
      </button>
    </form>
  );
}

function SignUp() {

  return (
    <main className="w-full min-h-screen flex flex-col items-center justify-center px-4 mt-4 mb-4">
      <div className="max-w-sm w-full text-gray-600">
        <div className="text-center mb-6">
          <div className="space-y-2">
            <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">
              Create your Account
            </h3>
            <p className="mt-2">
              Already have an account?{" "}
              <Link
                to="/signin"
                className="font-medium text-yellow-600 hover:text-yellow-500"
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
        <UserSignUp />
      </div>
    </main>
  );
}

export default SignUp;
