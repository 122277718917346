import React, { useContext, useEffect, useState } from "react";
import { Context as GeneralContext } from "../context/GeneralContext";
import { useNavigate } from "react-router-dom";
const CartItem = ({ product }) => {
  const { removeFromCart, setNewQuantityInCart } = useContext(GeneralContext);
  const { id, name, image, price } = product;
  const [quantity, setQuantity] = useState(product.quantity);
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event);
    // Ensure the new quantity is at least 1
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
      setNewQuantityInCart(id, newQuantity);
    } else {
      // If the new quantity is less than 1, set it to 1
      setQuantity(1);
    }
  };

  const handleRemoveClick = () => {
    // Call the onRemove function with the product id to remove it from the cart
    removeFromCart(id);
  };

  return (
    <div className="flex items-center justify-between border-b border-gray-300 py-4">
      <div className="flex items-center">
        {/* Product Image */}
        <img
          src={image}
          alt={name}
          className="h-20 w-32 object-contain rounded mr-4 border-2 border-gray-500"
        />
        {/* Product Name */}
        <div>
          <h3 className="text-lg font-semibold">{name}</h3>
          {/* Product Price */}
          <p className="text-gray-600">${price}</p>
        </div>
      </div>
      <div className="flex items-center">
        {/* Quantity Selector */}
        <div className="flex items-center">
          <button
            className="border border-gray-300 rounded px-2"
            onClick={() => handleQuantityChange(quantity - 1)}
          >
            -
          </button>
          <span className="mx-2">{quantity}</span>
          <button
            className="border border-gray-300 rounded px-2"
            onClick={() => handleQuantityChange(quantity + 1)}
          >
            +
          </button>
        </div>
        {/* Remove Button */}
        <button onClick={handleRemoveClick} className="ml-4 text-red-600">
          Remove
        </button>
      </div>
    </div>
  );
};
function Cart() {
  const { state } = useContext(GeneralContext);
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCartItems(state?.cart);
  }, [state?.cart]);

  return (
    <div>
      <div className="flex justify-between items-center my-4">
        <h2 className="text-3xl font-bold">Cart</h2>
        {/* Checkout Button */}
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => {
            cartItems.length > 0
              ? navigate("/checkout")
              : window.alert("Cart is Empty.");
          }}
        >
          Checkout
        </button>
      </div>

      {cartItems.length !== 0 ? (
        cartItems.map((item) => <CartItem key={item.id} product={item} />)
      ) : (
        <p>No Items in the Cart Yet</p>
      )}
    </div>
  );
}

export default Cart;
