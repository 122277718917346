import React, { useContext, useEffect, useState } from "react";
import { Context as AuthContext } from "../context/GeneralContext";
import emptyProfile from "../images/empty-profile-picture.png";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { writeToDatabase } from "../dbFuntions";
import visaLogo from "../images/Visa.png";
import mastercardLogo from "../images/MasterCard.png";

const Profile = () => {
  const { state, updateProfile, updatePaymentCard, updateLocalAddress } =
    useContext(AuthContext);
  const [phoneEdit, setPhoneEdit] = useState(false);
  const [editedPhone, setEditedPhone] = useState(state?.user?.phone);
  const [addAddress, setAddAddress] = useState(false);
  const [profileImage, setProfileImage] = useState(state?.user?.profilePicture);
  const [addPaymentCard, setAddPaymentCard] = useState(false);
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);

  // States for adding address
  const [newAddress, setNewAddress] = useState("");
  const [addresses, setAddresses] = useState([]); // Stores multiple addresses

  // States for adding payment card
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [paymentCards, setPaymentCards] = useState([]); // Stores multiple payment cards

  useEffect(() => {
    state?.user?.Cards && setPaymentCards(state?.user?.Cards);
    state?.user?.Address && setAddresses(state?.user?.Address);
  }, [state?.user]);
  const saveAddress = () => {
    if (newAddress !== "") {
      const updatedAddresses = [...addresses, newAddress];
      updateLocalAddress(updatedAddresses);
      setNewAddress("");
      setAddAddress(false);
    }
  };

  const savePaymentCard = () => {
    if (cardNumber !== "" && expirationDate !== "") {
      // Use regular expressions to identify Visa or Mastercard based on the card number
      const visaPattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
      const mastercardPattern = /^5[1-5][0-9]{14}$/;

      let cardType = "";
      if (visaPattern.test(cardNumber)) {
        cardType = "Visa";
      } else if (mastercardPattern.test(cardNumber)) {
        cardType = "Mastercard";
      } else {
        // If the card type is neither Visa nor Mastercard, do not save the card
        alert(
          "Only Visa or Mastercard are supported. Valid Visa card numbers start with 4 followed by 12 or 15 digits. Valid Mastercard card numbers start with 5 followed by specific digits (usually 1-5) and then 14 digits in total. "
        );
        return;
      }

      // Save the card if it's Visa or Mastercard
      const updatedCards = [
        ...paymentCards,
        { cardNumber, expirationDate, cardType },
      ];
      updatePaymentCard(updatedCards);

      // Clear input fields and toggle addPaymentCard state
      setCardNumber("");
      setExpirationDate("");
      setAddPaymentCard(false);
    }
  };

  useEffect(() => {
    updateAddress();
  }, [addresses]);
  useEffect(() => {
    updateCard();
  }, [paymentCards]);
  // State to track image update

  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    try {
      setIsUpdatingImage(true);
      // Upload the image to Firebase Cloud Storage
      const storageRef = ref(storage, `profile_images/${state.user.userId}`);
      await uploadBytes(storageRef, file);

      // Retrieve the download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageRef);
      writeToDatabase(
        `userdetails/${state.user.userId}/profilePicture/`,
        downloadURL
      );
      // Set the profileImage state to the download URL
      updateProfile({ downloadURL });
      setProfileImage(downloadURL);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUpdatingImage(false); // Set loader to false when image update ends
    }
  };
  const handlePhoneChange = (e) => {
    const input = e.target.value;
    // Remove non-numeric characters
    const numericInput = input.replace(/\D/g, "");
    setEditedPhone(numericInput);
  };
  const updatePhoneNumber = () => {
    writeToDatabase(`userdetails/${state.user.userId}/phone/`, editedPhone);
  };
  const updateCard = () => {
    writeToDatabase(`userdetails/${state.user.userId}/Cards/`, paymentCards);
  };
  const updateAddress = () => {
    writeToDatabase(`userdetails/${state.user.userId}/Address/`, addresses);
  };
  const ScrollableList = ({ items, renderItem }) => (
    <div className="max-h-40 overflow-auto">{items.map(renderItem)}</div>
  );
  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-lg mt-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Left Column */}
          <div className="col-span-1 bg-white rounded-lg shadow-md">
            <div className="p-6">
              <img
                src={profileImage ? profileImage : emptyProfile}
                alt="Profile"
                className="w-24 h-24 rounded-full mx-auto mb-4"
              />
              <div className="text-center">
                <h2 className="text-xl font-semibold">{state.user.username}</h2>
                <p className="text-gray-600">{state.user.email}</p>
              </div>
            </div>
          </div>

          {/* Middle Column */}
          <div className="col-span-2 bg-white rounded-lg shadow-md">
            <div className="p-6">
              <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">General Settings</h3>
                <div className="flex items-center mb-2">
                  <label className="mr-2">Email:</label>

                  <span>{state.user.email}</span>
                </div>
                <div className="flex items-center mb-2">
                  <label className="mr-2">Phone Number:</label>
                  {phoneEdit ? (
                    <input
                      type="tel"
                      className="border rounded px-2 py-1 focus:outline-none"
                      defaultValue={editedPhone}
                      onChange={handlePhoneChange}
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    />
                  ) : (
                    <span>{editedPhone}</span>
                  )}
                  <button
                    className="ml-2 border-yellow-500 border-2 text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    onClick={() => {
                      setPhoneEdit(!phoneEdit);
                      updatePhoneNumber();
                    }}
                  >
                    {phoneEdit ? "Save" : "Edit"}
                  </button>
                </div>
                <div className="mt-4">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfileImageChange}
                    className="hidden"
                    id="profileImageInput"
                  />
                  <label
                    htmlFor="profileImageInput"
                    className=" border-yellow-500 border-2 text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                  >
                    Change Profile Photo
                  </label>
                </div>
              </div>
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Account Settings</h3>

                {/* Delivery Address Section */}
                <div className="mb-4">
                  <h3 className="text-lg font-semibold mb-2">
                    Delivery Address
                  </h3>
                  {!addAddress && (
                    <button
                      onClick={() => setAddAddress(true)}
                      className="border-yellow-500 border-2 text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300 ease-in-out"
                    >
                      Add Delivery Address
                    </button>
                  )}
                  {addAddress && (
                    <div className="mb-2">
                      <input
                        type="text"
                        placeholder="Enter Address"
                        value={newAddress}
                        onChange={(e) => setNewAddress(e.target.value)}
                        className="border rounded px-2 py-1 focus:outline-none mr-2"
                      />
                      <button
                        onClick={saveAddress}
                        className="border-yellow-500 border-2 text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out"
                      >
                        Save Address
                      </button>
                    </div>
                  )}
                  {addresses.length > 0 && (
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Saved Addresses
                      </h3>
                      <ScrollableList
                        items={addresses}
                        renderItem={(address, index) => (
                          <div key={index} className="p-2 border-b">
                            {address}
                          </div>
                        )}
                      />
                    </div>
                  )}
                </div>

                {/* Payment Card Section */}
                <div className="mb-4">
                  <h3 className="text-lg font-semibold mb-2">Payment Card</h3>
                  {!addPaymentCard && (
                    <button
                      onClick={() => setAddPaymentCard(true)}
                      className="border-yellow-500 border-2 text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300 ease-in-out"
                    >
                      Add Payment Card
                    </button>
                  )}
                  {addPaymentCard && (
                    <div>
                      <input
                        type="text"
                        placeholder="Card Number"
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}
                        className="border rounded px-2 py-1 focus:outline-none mt-2"
                      />
                      <input
                        type="text"
                        placeholder="Expiration Date"
                        value={expirationDate}
                        onChange={(e) => setExpirationDate(e.target.value)}
                        className="border rounded px-2 py-1 focus:outline-none mt-2"
                      />
                      <button
                        onClick={savePaymentCard}
                        className="border-yellow-500 border-2 text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out mt-2"
                      >
                        Save Card
                      </button>
                    </div>
                  )}
                  {/* Payment Cards List */}
                  {paymentCards.length > 0 && (
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">
                        Saved Payment Cards
                      </h3>
                      <ScrollableList
                        items={paymentCards}
                        renderItem={(
                          { cardNumber, expirationDate, cardType },
                          index
                        ) => {
                          let cardLogo = null;

                          // Determine which card logo to use based on the card type
                          if (cardType === "Visa") {
                            cardLogo = visaLogo;
                          } else if (cardType === "Mastercard") {
                            cardLogo = mastercardLogo;
                          }

                          return (
                            <div
                              key={index}
                              className="p-2 border-b flex items-center"
                            >
                              {cardLogo && (
                                <img
                                  src={cardLogo}
                                  alt={cardType}
                                  className="w-8 h-8 mr-2"
                                />
                              )}
                              <div>
                                <p className="text-sm font-semibold">{`Card: ${cardNumber}`}</p>
                                <p className="text-xs text-gray-500">{`Expires: ${expirationDate}`}</p>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isUpdatingImage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
          <div className="w-16 h-16 border-t-4 border-b-4 border-yellow-500 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default Profile;
