import Slider from "../components/Slider";
import CTA from "../components/CTA";
import About from "../components/AboutUs";
import CardGrid from "../components/CardGrid";
import { useContext, useEffect, useState } from "react";
import { Context as GeneralContext } from "../context/GeneralContext";
import { readFromDatabase, writeToDatabase } from "../dbFuntions";

//import classes from './Home.module.css';

function Home() {
  const { getStore, state, getClass, getCourse, getProduct } =
    useContext(GeneralContext);
  const [topProducts, setTopProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const userId = state?.user?.userId;
  useEffect(() => {
    userId && getStore({ userId });
    userId && getClass({ userId });
    const classId = state?.class?.storeId;
    const storeId = state?.store?.storeId;
    storeId && getProduct({ storeId });
    classId && getCourse({ classId });
    const products = state?.product;
    const courses = state?.course;
    const calculateStoreRating = () => {
      if (!products) return;

      let totalRatingSum = 0;
      let totalRatingsCount = 0;

      // Iterate over products to calculate total rating sum and count
      products.forEach((product) => {
        totalRatingSum += product.rating;
        totalRatingsCount++;
      });

      // Calculate average rating
      const averageRating =
        totalRatingsCount > 0 ? totalRatingSum / totalRatingsCount : 0;

      writeToDatabase(`Store/${state?.store?.storeId}/rating/`, averageRating);
      // Update store's rating
      // Make sure to replace 'storeRating' with the correct state and action to update the store rating
      // storeRating({ storeId, rating: averageRating });
    };
    const calculateClassRating = () => {
      if (!courses) return;

      let totalRatingSum = 0;
      let totalRatingsCount = 0;

      // Iterate over products to calculate total rating sum and count
      courses.forEach((product) => {
        totalRatingSum += product.rating;
        totalRatingsCount++;
      });

      // Calculate average rating
      const averageRating =
        totalRatingsCount > 0 ? totalRatingSum / totalRatingsCount : 0;

      writeToDatabase(`Class/${state?.class?.storeId}/rating/`, averageRating);
      // Update store's rating
      // Make sure to replace 'storeRating' with the correct state and action to update the store rating
      // storeRating({ storeId, rating: averageRating });
    };
    products && calculateStoreRating();
    courses && calculateClassRating();
  }, [userId, state?.class?.storeId, state?.store?.storeId, state?.user]);

  useEffect(() => {
    readFromDatabase("Products/")
      .then((products) => {
        if (products) {
          const productsArray = Object.keys(products).map((productId) => ({
            productId,
            ...products[productId],
          }));
          setAllProducts(productsArray);
        } else {
          console.log("products not found.");
        }
      })
      .catch((error) => {
        console.error("Error finding products:", error);
      });
  }, []);

  useEffect(() => {
    const sortedProducts =
      allProducts &&
      [...allProducts].sort((a, b) => b.rating - a.rating).slice(0, 4);
    setTopProducts(sortedProducts ? sortedProducts : []);
  }, [allProducts]);

  return (
    <>
      <div>
        <Slider />
        <CardGrid data={topProducts} gridSize={4} />{" "}
        {/* Now using topProducts */}
        <CTA />
        <About />
      </div>
    </>
  );
}

export default Home;
