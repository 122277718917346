import { useEffect, useState } from "react";
import Card from "./Card";

function CardGrid({ screen, data, gridSize }) {
  if (!screen) screen = "";

  const [grid, setGrid] = useState(getGridSize());

  // Function to calculate the grid size based on the window width
  function getGridSize() {
    if (window.innerWidth < 768) {
      return 1; // sm screens
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      return 2; // md screens
    } else {
      return gridSize; // lg and above
    }
  }

  // Effect to adjust the grid size on window resize
  useEffect(() => {
    function handleResize() {
      setGrid(getGridSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="mx-auto px-4 max-w-screen-xl md:px-8">
      <div
        className={`mt-4 mb-5 grid gap-2 sm:grid-cols-1 md:grid-cols-2 `}
        style={{ gridTemplateColumns: `repeat(${grid}, minmax(0, 1fr))` }}
      >
        {data.map((post) => (
          <Card screen={screen} ke={Math.random()} items={post} />
        ))}
      </div>
    </section>
  );
}

export default CardGrid;
