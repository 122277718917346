import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useState } from "react";
import { storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import { Context as GeneralContext } from "../context/GeneralContext";
import { pushToDatabase } from "../dbFuntions";

function CourseForm() {
  const { state, getCourse } = useContext(GeneralContext);
  const [courseName, setCourseName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [category, setCategory] = useState("");
  const [rating, setRating] = useState(0);
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);
  const [imageerrorMessage, setImageErrorMessage] = useState("");
  const [videoerrorMessage, setVideoErrorMessage] = useState("");
  const navigate = useNavigate();
  const classId = state.class.storeId;

  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleThumbnailChange = async (e) => {
    const file = e.target.files[0];
    try {
      setIsUpdatingImage(true);
      // Upload the image to Firebase Cloud Storage
      if (
        !file ||
        !(
          file.name.toLowerCase().endsWith(".jpg") ||
          file.name.toLowerCase().endsWith(".jpeg") ||
          file.name.toLowerCase().endsWith(".png")
        )
      ) {
        console.error("Please select an image file (jpg, jpeg, png)");
        setImageErrorMessage(
          "Please select correct file type (jpg, jpeg, png)"
        );
        // Optionally, you can set an error message to inform the user
        return;
      } else {
        setImageErrorMessage("");
      }
      const storageRef = ref(storage, `course_images/${file.name}`);
      await uploadBytes(storageRef, file);

      // Retrieve the download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageRef);

      setThumbnail(downloadURL);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUpdatingImage(false); // Set loader to false when image update ends
    }
  };

  const handleVideoChange = async (e) => {
    const file = e.target.files[0];
    try {
      setIsUpdatingImage(true);
      // Upload the image to Firebase Cloud Storage
      if (!file || !file.name.toLowerCase().endsWith(".mp4")) {
        console.error("Please select an mp4 file");
        setVideoErrorMessage("Please select correct file type");
        // Optionally, you can set an error message to inform the user
        return;
      } else {
        setVideoErrorMessage("");
      }
      const storageRef = ref(storage, `course_images/${file.name}`);
      await uploadBytes(storageRef, file);

      // Retrieve the download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageRef);

      setVideo(downloadURL);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUpdatingImage(false); // Set loader to false when image update ends
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., sending data to backend
    pushToDatabase("Course/", {
      courseName,
      description,
      thumbnail,
      video,
      rating,
      category,
      classId,
    });
    // Reset form fields after submission
    setCourseName("");
    setDescription("");
    setThumbnail(null);
    setVideo(null);
    getCourse({ classId });
    navigate("/dashboard");
  };

  const categoryOptions = [
    "Paintings",
    "Sculptures",
    "Photography",
    "Drawings and Illustrations",
  ];

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-lg mt-8">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-semibold mb-4">Create a Course</h2>
          <form onSubmit={handleSubmit}>
            {isUpdatingImage && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
                <div className="w-16 h-16 border-t-4 border-b-4 border-yellow-500 rounded-full animate-spin"></div>
              </div>
            )}
            <div className="mb-4">
              <label
                htmlFor="courseName"
                className="block text-sm font-medium text-gray-700"
              >
                Course Name
              </label>
              <input
                type="text"
                id="courseName"
                value={courseName}
                onChange={handleCourseNameChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={handleDescriptionChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                rows="4"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                htmlFor="thumbnail"
                className="block text-sm font-medium text-gray-700"
              >
                Thumbnail Photo
              </label>
              {imageerrorMessage && (
                <p className="text-red-500 text-sm mt-2">{imageerrorMessage}</p>
              )}
              <input
                type="file"
                id="thumbnail"
                onChange={handleThumbnailChange}
                accept="image/*"
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="video"
                className="block text-sm font-medium text-gray-700"
              >
                Upload Video
              </label>
              {videoerrorMessage && (
                <p className="text-red-500 text-sm mt-2">{videoerrorMessage}</p>
              )}
              <input
                type="file"
                id="video"
                onChange={handleVideoChange}
                accept="video/*"
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700"
              >
                Category
              </label>
              <select
                id="category"
                value={category}
                onChange={handleCategoryChange}
                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border p-2"
                required
              >
                <option value="">Select a category</option>
                {categoryOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create Course
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CourseForm;
