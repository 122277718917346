import React, { useContext, useEffect, useState } from "react";
import CardGrid from "../components/CardGrid";
import { useNavigate } from "react-router-dom";
import { Context as GeneralContext } from "../context/GeneralContext";
import emptyProfile from "../images/empty-profile-picture.png";
import {
  addToCompletedOrders,
  readFromDatabase,
  removeFromUnderProcessOrders,
} from "../dbFuntions";

const PendingOrderItem = ({
  pic,
  name,
  quantity,
  price,
  orderId,
  onDispatch,
}) => {
  return (
    <div className="flex items-center justify-between md:p-4 py-2 border-b">
      <img
        src={pic}
        alt={name}
        className="hidden  md:block md:w-20 md:h-20 md:object-contain md:border md:border-1 md:rounded-mg"
      />
      <span className="flex-1 md:ml-4">{name}</span>
      <span className="w-20 text-center">{quantity}</span>
      <span className="w-20 text-center">${price}</span>
      <button
        onClick={onDispatch}
        className="md:w-32 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 md:px-4 rounded"
      >
        Dispatch
      </button>
    </div>
  );
};

const Dashboard = () => {
  const { state } = useContext(GeneralContext);
  const [stores, setStores] = useState([]);
  const [classes, setClasses] = useState([]);
  const [products, setProducts] = useState([]);
  const [courses, setCourses] = useState([]);
  const [underReviewProducts, setUnderReviewProducts] = useState([]);

  const [isStoreCreated, setIsStoreCreated] = useState(false);
  const [isClassCreated, setISClassCreated] = useState(false);

  useEffect(() => {
    if (state?.store !== null) setStores([state?.store]);
    else setStores([]);
    if (state?.class !== null) setClasses([state?.class]);
    else setClasses([]);
    if (state?.course !== null) setCourses(state?.course);
    else setCourses([]);
    if (state?.product !== null) setProducts(state?.product);
    else setProducts([]);
  }, [state]);

  const navigate = useNavigate();

  useEffect(() => {
    state?.store !== null && setIsStoreCreated(true);
    state?.class !== null && setISClassCreated(true);
  }, [state.store, state.class]);

  const handleNavigateToStoreForm = () => {
    navigate("/StoreForm");
  };

  const handleNavigateToClassForm = () => {
    navigate("/ClassForm");
  };

  const handleNavigateToProductForm = () => {
    if (isStoreCreated) navigate("/ProductForm");
    else window.alert("Create a Store first");
  };

  const handleNavigateToCourseForm = () => {
    if (isClassCreated) navigate("/CourseForm");
    else window.alert("Create a Class first");
  };

  const handleNavigateToProductViewAll = () => {
    navigate(`/store/${state?.store.storeId}`);
  };

  const handleNavigateToViewAllCourse = () => {
    navigate(`/classpage/${state?.course[0].classId}`);
  };

  const handleDispatch = async (product) => {
    try {
      // Add the order to CompletedOrders first to ensure it's not lost
      await addToCompletedOrders(product.orderId, {
        pic: product.productImage,
        name: product.name,
        quantity: product.quantity,
        price: product.price,
        storeId: product.storeId,
        userId: product.userId,
        // Add any other details you need in CompletedOrders
      });

      // Then remove the order from UnderProcessOrders
      await removeFromUnderProcessOrders(product.orderId);

      // Update state to remove the item from the UI
      setUnderReviewProducts(
        underReviewProducts.filter((item) => item.orderId !== product.orderId)
      );
    } catch (error) {
      console.error("Error dispatching order:", error);
    }
  };
  useEffect(() => {
    readFromDatabase("UnderProcessOrders/")
      .then((details) => {
        const filteredOrders = Object.entries(details).reduce(
          (acc, [key, value]) => {
            if (value.storeId === state?.store?.storeId) {
              acc.push({
                ...value, // Spread the existing order data
                orderId: key, // Add the parent key as orderId
              });
            }
            return acc;
          },
          []
        );
        setUnderReviewProducts(filteredOrders);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state?.store?.storeId]);

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-xl mt-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Left Column - User Details */}
          <div className="col-span-1 bg-white rounded-lg shadow-md">
            <div className="p-6">
              <img
                src={
                  state?.user?.profilePicture
                    ? state.user.profilePicture
                    : emptyProfile
                }
                alt="Profile"
                className="w-24 h-24 rounded-full mx-auto mb-4"
              />
              <div className="text-center">
                <h2 className="text-xl font-semibold">{state.user.username}</h2>
                <p className="text-gray-600">{state.user.email}</p>
              </div>
            </div>
          </div>

          {/* Middle Column - Store Details */}
          <div className="col-span-2 bg-white rounded-lg shadow-md">
            <div className="p-6">
              {stores.length > 0 ? (
                stores.map((store) => (
                  <div key={store.id} className="mb-4">
                    <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
                      Store Details
                    </h2>
                    <img
                      src={store.image}
                      alt={store.storeName}
                      className="w-24 h-24 rounded-full mx-1 mb-2"
                    />
                    <h3 className="text-lg font-semibold">{store.storeName}</h3>
                    <p className="text-gray-600">
                      Rating: {store.rating && store.rating.toFixed(1)}
                    </p>
                    <p className="text-gray-600">
                      Description: {store.description}
                    </p>
                  </div>
                ))
              ) : (
                <div>
                  <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
                    Store Details
                  </h2>
                  <p>No store created</p>
                  <button
                    className="mt-2 border-yellow-500 border-2 text-lg text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    onClick={handleNavigateToStoreForm}
                  >
                    Create Store
                  </button>
                </div>
              )}
            </div>

            <div className="p-6">
              <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
                Pending Orders
              </h2>
              {underReviewProducts.length > 0 ? (
                underReviewProducts.map((product) => (
                  <PendingOrderItem
                    key={product.orderId}
                    pic={product.productImage || "defaultImageURL"} // Replace 'defaultImageURL' with an actual URL or a placeholder
                    name={product.name}
                    quantity={product.quantity}
                    price={product.price}
                    orderId={product.orderId}
                    onDispatch={() => handleDispatch(product)}
                  />
                ))
              ) : (
                <div>
                  <p>No Pending Orders</p>
                </div>
              )}
            </div>
            {/* Products */}
            <div className="p-6">
              {products && products.length > 0 ? (
                <div>
                  {" "}
                  <h2 className="text-2xl font-semibold mb-4 lg:flex sm:md:flex-row  items-center justify-between">
                    Products
                    <div>
                      <button
                        className="mr-2 border-yellow-500 border-2 text-lg text-black px-4 py-1 my-2 md:my-0  rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                        onClick={handleNavigateToProductViewAll}
                      >
                        View All
                      </button>
                      <button
                        className="border-yellow-500 border-2 text-lg text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                        onClick={handleNavigateToProductForm}
                      >
                        Create New Product
                      </button>
                    </div>
                  </h2>
                  <CardGrid
                    screen={"DashBoard/Product"}
                    data={products}
                    gridSize={3}
                  />
                </div>
              ) : (
                <div>
                  <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
                    Products
                  </h2>
                  <p>No Products Uploaded</p>
                  <button
                    className="mt-2 border-yellow-500 border-2 text-lg text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    onClick={handleNavigateToProductForm}
                  >
                    Create New Product
                  </button>
                </div>
              )}
            </div>

            {/* Class */}
            <div className="p-6">
              {classes.length > 0 ? (
                classes.map((classe) => (
                  <div key={classe.id} className="mb-4">
                    <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
                      Class Details
                    </h2>
                    <img
                      src={classe.image}
                      alt={classe.className}
                      className="w-24 h-24 rounded-full mx-1 mb-2"
                    />
                    <h3 className="text-lg font-semibold">
                      {classe.className}
                    </h3>
                    <p className="text-gray-600">
                      Rating: {classe.rating && classe.rating.toFixed(1)}
                    </p>
                  </div>
                ))
              ) : (
                <div>
                  <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
                    Class Details
                  </h2>
                  <p>No Class created</p>
                  <button
                    className="mt-2 border-yellow-500 border-2 text-lg text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    onClick={handleNavigateToClassForm}
                  >
                    Create Class
                  </button>
                </div>
              )}
            </div>
            {/* Courses */}
            <div className="p-6">
              {courses.length > 0 ? (
                <div>
                  {" "}
                  <h2 className="text-2xl font-semibold mb-4 lg:flex sm:md:flex-row items-center justify-between">
                    Courses
                    <div>
                      <button
                        className="mr-2 border-yellow-500 border-2 text-lg text-black px-4 py-1 my-2 md:my-0 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                        onClick={handleNavigateToViewAllCourse}
                      >
                        View All
                      </button>
                      <button
                        className="border-yellow-500 border-2 text-lg text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                        onClick={handleNavigateToCourseForm}
                      >
                        Create New Course
                      </button>
                    </div>
                  </h2>
                  {courses && (
                    <CardGrid
                      screen={"DashBoard/Class"}
                      data={courses}
                      gridSize={3}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
                    Courses
                  </h2>
                  <p>No Courses Uploaded</p>
                  <button
                    className="mt-2 border-yellow-500 border-2 text-lg text-black px-4 py-1 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    onClick={handleNavigateToCourseForm}
                  >
                    Create New Course
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
