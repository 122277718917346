import createDataContext from "./createDataContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { findCourse, findProduct, findStore, findUser } from "../dbFuntions";

const generalreducer = (state, action) => {
  switch (action.type) {
    case "addUser":
      return { ...state, user: action.payload };
    case "deleteUser":
      return { ...state, user: null };
    case "updateCard":
      return {
        ...state,
        user: { ...state.user, Cards: action.payload },
      };
    case "updateAddress":
      return {
        ...state,
        user: { ...state.user, Address: action.payload },
      };
    case "updateUser":
      return {
        ...state,
        user: { ...state.user, profilePicture: action.payload },
      };
    case "updateStore":
      return { ...state, store: action.payload };
    case "clearStore":
      return { ...state, store: null };
    case "updateClass":
      return { ...state, class: action.payload };
    case "clearClass":
      return { ...state, class: null };
    case "updateCourse":
      return { ...state, course: action.payload };
    case "clearCourse":
      return { ...state, course: null };
    case "updateProduct":
      return { ...state, product: action.payload };
    case "clearProduct":
      return { ...state, product: null };
    case "addToCart":
      const existingItemIndex = state.cart.findIndex(
        (item) => item.id === action.payload.id
      );
      if (existingItemIndex !== -1) {
        // Item already exists, update the quantity
        const updatedCart = [...state.cart];
        updatedCart[existingItemIndex].quantity += action.payload.quantity;
        return { ...state, cart: updatedCart };
      } else {
        // Item doesn't exist, add it to the cart
        return { ...state, cart: [...state.cart, action.payload] };
      }
    case "removeFromCart":
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
      };
    case "setNewQuantityInCart":
      const { id, quantity } = action.payload;
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === id ? { ...item, quantity: quantity } : item
        ),
      };

    case "clearCart":
      return { ...state, cart: [] };
    default:
      return state;
  }
};

const signIn = (dispatch) => {
  return async ({ email, password, setSignInError, rememberMe, callback }) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            // User is signed in.
            findUser("userdetails/", user.email)
              .then((currentuser) => {
                if (currentuser) {
                  dispatch({ type: "addUser", payload: currentuser });
                } else {
                  console.log("User not found.");
                }
              })
              .catch((error) => {
                console.error("Error finding user:", error);
              });
          } else {
            // No user is signed in.
            console.log("No user signed in.");
          }
        });
        setSignInError("");
        if (rememberMe) {
          localStorage.setItem("rememberedEmail", email);
        } else {
          localStorage.removeItem("rememberedEmail");
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        // If sign-in fails, determine the error type and set error message accordingly
        const errorMessage = error.message;
        console.log(errorMessage);
        if (errorMessage === "Firebase: Error (auth/invalid-credential).") {
          setSignInError(
            "User not found. Please check your email or password."
          );
        } else {
          setSignInError("An error occurred. Please try again later.");
        }
      });
  };
};

const updatePaymentCard = (dispatch) => {
  return (updatedCard) => {
    dispatch({ type: "updateCard", payload: updatedCard });
  };
};
const updateLocalAddress = (dispatch) => {
  return (updatedAddress) => {
    dispatch({ type: "updateAddress", payload: updatedAddress });
  };
};

const logout = (dispatch) => {
  return async () => {
    try {
      dispatch({ type: "deleteUser" });
      dispatch({ type: "clearCart" });
      dispatch({ type: "clearCourse" });
      dispatch({ type: "clearProduct" });
      dispatch({ type: "clearClass" });
      dispatch({ type: "clearStore" });
    } catch (err) {}
  };
};

const updateProfile = (dispatch) => {
  return async (profilePicture) => {
    try {
      dispatch({ type: "updateUser", payload: profilePicture.downloadURL });
    } catch (err) {}
  };
};

const getStore = (dispatch) => {
  return async (userId) => {
    try {
      findStore("Store/", userId.userId)
        .then((store) => {
          if (store) {
            dispatch({ type: "updateStore", payload: store });
          } else {
            dispatch({ type: "clearStore" });
          }
        })
        .catch((error) => {
          console.error("Error finding Store:", error);
        });
    } catch (err) {}
  };
};

const getClass = (dispatch) => {
  return async (userId) => {
    try {
      findStore("Class/", userId.userId)
        .then((Class) => {
          if (Class) {
            dispatch({ type: "updateClass", payload: Class });
          } else {
            dispatch({ type: "clearClass" });
          }
        })
        .catch((error) => {
          console.error("Error finding Class:", error);
        });
    } catch (err) {}
  };
};

const getCourse = (dispatch) => {
  return async (classId) => {
    try {
      findCourse("Course/", classId.classId)
        .then((course) => {
          if (course) {
            dispatch({ type: "updateCourse", payload: course });
          } else {
            dispatch({ type: "clearCourse" });
          }
        })
        .catch((error) => {
          console.error("Error finding course:", error);
        });
    } catch (err) {}
  };
};

const getProduct = (dispatch) => {
  return async (storeId) => {
    try {
      findProduct("Products/", storeId.storeId)
        .then((product) => {
          if (product) {
            dispatch({ type: "updateProduct", payload: product });
          } else {
            dispatch({ type: "clearProduct" });
          }
        })
        .catch((error) => {
          console.error("Error finding product:", error);
        });
    } catch (err) {}
  };
};

const editCart = (dispatch) => {
  return async (storeId) => {
    try {
      findProduct("Products/", storeId.storeId)
        .then((product) => {
          if (product) {
            dispatch({ type: "updateProduct", payload: product });
          } else {
            dispatch({ type: "clearProduct" });
          }
        })
        .catch((error) => {
          console.error("Error finding product:", error);
        });
    } catch (err) {}
  };
};

const addToCart = (dispatch) => {
  return async (id, name, image, quantity, price, storeId) => {
    try {
      dispatch({
        type: "addToCart",
        payload: { id, name, image, quantity, price, storeId },
      });
    } catch (err) {}
  };
};

const removeFromCart = (dispatch) => {
  return async (id) => {
    try {
      dispatch({ type: "removeFromCart", payload: id });
    } catch (err) {}
  };
};

const setNewQuantityInCart = (dispatch) => {
  return async (id, quantity) => {
    try {
      dispatch({ type: "setNewQuantityInCart", payload: { id, quantity } });
    } catch (err) {}
  };
};

const clearCart = (dispatch) => {
  return async () => {
    try {
      dispatch({ type: "clearCart" });
    } catch (err) {}
  };
};

export const { Provider, Context } = createDataContext(
  generalreducer,
  {
    signIn,
    logout,
    updateProfile,
    getStore,
    getClass,
    getCourse,
    getProduct,
    addToCart,
    removeFromCart,
    editCart,
    setNewQuantityInCart,
    clearCart,
    updatePaymentCard,
    updateLocalAddress,
  },
  {
    user: null,
    store: null,
    class: null,
    course: null,
    product: null,
    cart: [],
  }
);
